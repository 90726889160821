import gql from "graphql-tag";

export const CURRENT_USER_EMAIL_QUERY = gql`
  query CurrentUserEmail {
    currentUser {
      id
      email
    }
  }
`;
