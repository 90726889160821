import useQuery from "hooks/useQuery";
import { CurrentUserEmail } from "__generated__/CurrentUserEmail";
import { CURRENT_USER_EMAIL_QUERY } from "gql/user.gql";
import React from "react";

interface Props {
  children: React.ReactNode;
  renderedButHidden?: boolean; // this option still renders the children, but just hides them in a display:none
}

/**
 * Component only renders its children if user is a project dado employee
 */
export function InternalFeature(props: Props) {
  const { loading, data } = useQuery<CurrentUserEmail>(CURRENT_USER_EMAIL_QUERY);

  if (loading || !data) return null;

  const email = data.currentUser.email.toLowerCase();
  if (email.endsWith("@projectdado.com") || email.endsWith("@linqsoftware.com")) {
    return <>{props.children}</>;
  }

  if (props.renderedButHidden) {
    return <div style={{ display: "none" }}>{props.children}</div>;
  }

  return null;
}
